import React, { useState, useEffect } from "react";
import "./comp-five.scss";

import GoogleIconW from "../../../../img/googleIconw.png";
import MiIconW from "../../../../img/miIconw.png";
import OperaIconW from "../../../../img/operaIconw.png";
import CompassIconW from "../../../../img/compassIconw.png";
import ChromeIconW from "../../../../img/chromeIconw.png";
import EdgeIconW from "../../../../img/edgeIconw.png";
import DuckIconW from "../../../../img/duckIconw.png";
import AppleIcon from "../../../../img/appleIcon.png";
import AvailableOnW from "../../../../img/availableOnW.png";
import GoogleIconB from "../../../../img/googleIconB.png";
import MiIconB from "../../../../img/miIconB.png";
import OperaIconB from "../../../../img/operaIconB.png";
import CompassIconB from "../../../../img/compassIconB.png";
import ChromeIconB from "../../../../img/chromeIconB.png";
import EdgeIconB from "../../../../img/edgeIconB.png";
import DuckIconB from "../../../../img/duckIconB.png";
import GPlayIcon from "../../../../img/gPlayIcon.png";
import AvailableOnB from "../../../../img/availableOnB.png";
import Button from "../../../../img/ButtonStore.png";
import AppleText from "../../../../img/Applestore.png";
import GooglePlayText from "../../../../img/playstore.png";
// const Frame = "https://rentblob.blob.core.windows.net/pitch/img/Pitch-Deck/sixth-comp-img/frame500.png";

// background: ;
//
export default function CompFive(props) {
  const [isHover, setHover] = useState(false);
  const [flag, setFlag] = useState(false);

  function handleMouseEvent() {
    setHover((prev) => {
      return !prev;
    });
  }

  useEffect(() => {
    props.HoverID === 5 && setTimeout(handleMouseEvent, 200);

    setTimeout(() => {
      setFlag(true);
    }, 500);
  }, [props.HoverID]);

  useEffect(() => {
    setTimeout(() => {
      setFlag(!flag);
    }, 3000);
  }, [flag]);
  console.log(flag, "jdjd");

  return (
    <div className="comp-five-main-container">
      <div className="content-container5" style={{
        // background: flag && "linear-gradient(98deg, #1C1C1C 61.92%, rgba(255, 255, 255, 0.00) 239.12%)"
        background: flag && "#3b3a39"
      }}>
        {/* <img className="bg-img" src={Frame} alt=""/> */}
        <img className="google-iconw itemW" src={GoogleIconW} alt="" />
        <img className="mi-iconw itemW" src={MiIconW} alt="" />
        <img className="chrome-iconw itemW" src={ChromeIconW} alt="" />
        <img className="edge-iconw itemW" src={EdgeIconW} alt="" />
        <img className="compass-iconw itemW" src={CompassIconW} alt="" />
        <img className="duck-iconw itemW" src={DuckIconW} alt="" />
        <img className="opera-iconw itemW" src={OperaIconW} alt="" />
        <img className="availableOnW itemW" src={AvailableOnW} alt="" />
        <img
          className="google-iconw itemW"
          src={GoogleIconB}
          alt=""
          style={{ opacity: flag ? 1 : 0 }}
        />
        <img
          className="mi-iconw itemW"
          src={MiIconB}
          alt=""
          style={{ opacity: flag ? 1 : 0 }}
        />
        <img
          className="chrome-iconw itemW"
          src={ChromeIconB}
          alt=""
          style={{ opacity: flag ? 1 : 0 }}
        />
        <img
          className="edge-iconw itemW"
          src={EdgeIconB}
          alt=""
          style={{ opacity: flag ? 1 : 0 }}
        />
        <img
          className="compass-iconw itemW"
          src={CompassIconB}
          alt=""
          style={{ opacity: flag ? 1 : 0 }}
        />
        <img
          className="duck-iconw itemW"
          src={DuckIconB}
          alt=""
          style={{ opacity: flag ? 1 : 0 }}
        />
        <img
          className="opera-iconw itemW"
          src={OperaIconB}
          alt=""
          style={{ opacity: flag ? 1 : 0 }}
        />
        <img
          className="availableOnW itemW"
          src={AvailableOnB}
          alt=""
          style={{ opacity: flag ? 1 : 0 }}
        />
        <img className="btn-store itemW" src={Button} alt="" />
        <img className="apple-text itemW" src={AppleText} alt="" style={{opacity:flag && 0}}/>
        <img className="gplay-text itemW" src={GooglePlayText} alt="" style={{opacity:flag && 1}} />
        <img className="apple-icon itemW" src={AppleIcon} alt="" style={{transform:flag && `translate(105%,-27%)`,opacity:flag && 0}} />
        <img className="gPlay-icon itemW" src={GPlayIcon} alt=""  style={{transform:flag && `translate(105%,-27%)`,opacity:flag && 1}}/>
      </div>
    </div>
  );
}
