export const listData=[
    {
        listPara: "Experience seamless navigation with our clutter-free "
    },
    {
        listPara: "Range of "
    },
    {
        listPara: " for quality pet products and accessories"
    },
    {
        listPara: "with fellow Pet enthusiast"
    },
    {
        listPara: "Available on "
    },
    {
        listPara: " on Google Playstore"
    }
   
]