import React, { useState, useEffect } from "react";
import "./comp-two.scss";

import Spark from "../../../../img/SPARK.png";
// import BGimg from "../../../../img/compBG2.png";
import Dog from "../../../../img/dogu.png";
import DogBg from "../../../../img/doguBG.png";
import Razor from "../../../../img/Razor.png";
import Scissor1 from "../../../../img/scissor1.png";
import Scissor2 from "../../../../img/scissor2.png";
import Mirror from "../../../../img/mirror.png";
import Brush1 from "../../../../img/brush1.png";
import Brush2 from "../../../../img/brush2.png";
import WOOF from "../../../../img/WOOF.png";


export default function CompTwo(props) {

    const [frameAnime, setFrameAnime] = useState(false);
    const [rotateEffect, setRotateEffect] = useState(false);

    function handleMouseEvent() {
        setFrameAnime(prev => { return !prev })
    }
    useEffect(() => {
        props.HoverID === 2 && setTimeout(handleMouseEvent, 200)
    }, [props.HoverID])

    useEffect(()=>{
        setTimeout(()=>{
         setRotateEffect(prev => { return !prev });
        },2000)
    },[rotateEffect])

    return (
        <>
            <div className="comp-two-main-container" >
                <div className="content-container2">
                    {/* <img className="bg-img2" src={BGimg} alt="" /> */}
                    <img className="brush1" src={Brush1} alt="" style={{transform:frameAnime && !rotateEffect ? `rotate(0deg)` : rotateEffect && `rotate(10deg)`}}/>
                    <img className="brush2" src={Brush2} alt="" style={{transform:frameAnime && !rotateEffect ? `rotate(0deg)` : rotateEffect && `rotate(15deg)` }}/>
                    <img className="scissor1" src={Scissor1} alt="" 
                    style={{transform:frameAnime && !rotateEffect ? `rotate(0deg)` : rotateEffect && `rotate(15deg)`}}
                    />
                    <img className="scissor2" src={Scissor2} alt="" 
                    style={{transform:frameAnime && !rotateEffect ? `rotate(0deg)` : rotateEffect && `rotate(10deg)`}}
                    />
                    <img className="mirror" src={Mirror} alt="" 
                    style={{transform:frameAnime && !rotateEffect ? `rotate(0deg)` : rotateEffect && `rotate(-15deg)`}}
                    />
                    <img className="razor" src={Razor} alt="" 
                    style={{transform:frameAnime && !rotateEffect ? `rotate(0deg)` : rotateEffect && `rotate(-15deg)`}}
                    />
                    <img className="dog-bg" src={DogBg} alt="" />
                    <img className="dogu" src={Dog} alt="" style={{transform:frameAnime && `translateY(0)`}}/>

                    <img className="woof" src={WOOF} alt="" style={{opacity:frameAnime && 1}}/>
                    <img className="spark" src={Spark} alt="" style={{opacity:frameAnime && 1}} />

                     
                </div>
            </div>
        </>
    )
}