import React, { useState } from "react";
import "./thirdPD-comp.scss";
import ThirdPDCardComp from "./thirdPD-card-comp";
import thirdPDCompData from "./thirdPDCompData";
import ProfilingCard from "./Nested-Comp/Profiling-Card-Comp/profiling-card";
import MoveInPhotosCard from "./Nested-Comp/Move-In-Photos-Card-Comp/move-in-photos-card";
import RscoreCard from "./Nested-Comp/Rscore-Card-Comp/rscore-card";
import RentalManagementCard from "./Nested-Comp/Rental-Management-Card-Comp/rental-management-card";


export default function ThirdPDComp() {

    const [hiddenHoverDiv, setHiddenHoverDiv] = useState(false)
    let [cardItemID, setCardItemID] = useState(0);
    let [cardNum, setCardNum] = useState(0);

    function handleCardItemID(itemID) {
        setCardItemID(cardItemID = itemID + 1)

    }

    function cardNumberFunction(num) {
        setCardNum(cardNum = num + 1)
    }

    return (
        <>
            <div className="thirdPD-comp-main-container">
                <div className="hidden-hover-div"
                    style={{ visibility: hiddenHoverDiv && "visible"}}
                    onClick={()=>{ setHiddenHoverDiv(false)}}
                >
                    <div className="hidden-content-div">
                        {
                            cardItemID === 1 ? <ProfilingCard HiddenHoverDiv={hiddenHoverDiv} /> :
                                cardItemID === 2 ? <MoveInPhotosCard HiddenHoverDiv={hiddenHoverDiv} /> :
                                    cardItemID === 3 ? <RscoreCard HiddenHoverDiv={hiddenHoverDiv} /> :
                                        cardItemID === 4 && <RentalManagementCard HiddenHoverDiv={hiddenHoverDiv} />
                        }
                    </div>

                </div>
                <div className="top-part-div">
                    <div className="right-div">
                        <div className="shape1">
                            <h3>SOLUTION</h3>
                        </div>
                    </div>
                    <div className="left-div">
                        {/* <hr /> */}
                        <h3>
                        No Single Centralized Platform For Both <br />
                        <p className="para-holder">
                            <span>Products</span>
                            <span>&</span>
                            <span>Services </span>
                        </p>
                        </h3>
                    </div>
                </div>
                <div className="bottom-part-div">
                    {thirdPDCompData.map((item, index) => {
                        return (
                            <div
                                key={index}
                                onMouseEnter={() => { cardNumberFunction(index) }}
                                onMouseLeave={() => { cardNumberFunction(-1) }}
                            >
                                <ThirdPDCardComp
                                    id={index}
                                    Heading={item.heading}
                                    Para1={item.para1}
                                    Para2={item.para2}
                                    cardNumber={cardNum}
                                    // HandleHoverOnCard={handleHoverOnCard}
                                    // IsHoverOnCardDiv={isHoverOnCardDiv}
                                    HandleMouseFalseEven={()=>{setHiddenHoverDiv(false)}}
                                    HandleMouseTrueEven={()=>{setHiddenHoverDiv(true)}}
                                    // CardNumberFunction={cardNumberFunction}
                                    CardItemID={cardItemID}
                                    HandleCardItemID={handleCardItemID}
                                /></div>
                        )
                    })}
                </div>
            </div>
        </>
    );
}