import React,{useState, useEffect} from "react";
import "./comp-six.scss";

import Frame from "../../../../img/background_comp6.png";
import Mobile6 from "../../../../img/comp6_mobile.png";
import review1 from "../../../../img/review1.png";
import review2 from "../../../../img/review2.png";
import review3 from "../../../../img/review3.png";
import review4 from "../../../../img/review4.png";
// const Frame = "https://rentblob.blob.core.windows.net/pitch/img/Pitch-Deck/sixth-comp-img/closeup-hands-passing-contract-unrecognizable-businessman.png";
// const Phone1 = "https://rentblob.blob.core.windows.net/pitch/img/Pitch-Deck/sixth-comp-img/phone10.png";
// const Phone2 = "https://rentblob.blob.core.windows.net/pitch/img/Pitch-Deck/sixth-comp-img/phone11.png";




export default function CompSix(props){
    const [isHover, setHover] = useState(false)

    function handleMouseEvent() {
        setHover(prev => { return !prev })
    }

    useEffect(() => {
        props.HoverID === 6 && setTimeout(handleMouseEvent, 200)
    }, [props.HoverID])

    return (
        <>
            <div className="comp-six-main-container">
                <div className="content-container">
                    <img className="bg-img6" src={Frame} alt=""
                        style={{
                            left: isHover && "-5%"
                        }}
                    />
                    <img className="review1" src={review1} alt="" style={{transform: isHover && `translateX(0)`}}/>
                    <img className="review4" src={review4} alt="" style={{transform: isHover && `translateX(0)`}}/>
                    <img className="review3" src={review3} alt="" style={{transform: isHover && `translateX(0)`}}/>
                    <img className="review2" src={review2} alt="" style={{transform: isHover && `translateX(0)`}}/>
                    <img className="mobile6" src={Mobile6} alt="" style={{transform: isHover && `translateY(0)`}}/>

                   
                </div>
            </div>
        </>
    )
}