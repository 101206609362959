import React, { useState,useEffect } from "react";
import "./seventhPD-comp.scss";
import RangeCard from "./Nested-Comp/range-card";
// import TouchAppIcon from "@mui/icons-material/TouchApp";
// import ProgressCircle from "./Nested-Comp/progress-circle";
// import InfoCard from "./Nested-Comp/info-card";
import PetPie from "../../img/PetPie.png";
import PCDist from "../../img/PetCareDist.png";
import FastGrowMarket from "../../img/FGM.png";
import MarketSize from "../../img/MS.png";
import IndiaGrow from "../../img/IndGrowProject.png";
import BusinessText from "../../img/BUSINESSt.png";
import Traction from "../../components/Traction/Traction";



const LeftSides = "https://rentblob.blob.core.windows.net/turant/leftSides.png";
const RightSides = "https://rentblob.blob.core.windows.net/turant/rightSides.png";

export default function SeventhPDComp() {
  const Frame1 =
    "https://rentblob.blob.core.windows.net/pitch/img/Pitch-Deck/seventh-comp-img/frame-451.png";
  // const Logo = "https://rentblob.blob.core.windows.net/pitch/img/logo.svg";

  const [progressBar, setProgressBar] = useState(true);
  const [count, setCount] = useState(0);
  const [moreFlag,setMoreFlag]=useState(false);
  const [divFlag,setDivFlag]=useState(false);
  const [isIconHover,setIsIconHover]=useState(false);
  const [upDownEffect,setUpDownEffect]=useState(false);
  


  
  useEffect(() => {
    setTimeout(() => {
      setIsIconHover(!isIconHover);
    }, 800);
  }, [isIconHover]);
  
  useEffect(() => {
    setTimeout(() => {
      setUpDownEffect((prev) => {
        return !prev;
      });
    }, 800);
  },[upDownEffect]);

  function handleLeftClick() {
    if (count > 0) setCount(count - 1);
  }
  function handleRightClick() {
    if (count < 5) setCount(count + 1);
  }

  function handleSetClick(){
    setTimeout(()=>{setMoreFlag(true)},250);
    setTimeout(()=>{setDivFlag(true)},300);
}


  return (
    <>
      <div className="seventhPD-comp-main-container" onMouseEnter={() => {
            setProgressBar(false);
          }}>
        <div
          className="hover-hidden-div"
          onMouseEnter={() => {
            setProgressBar(false);
          }}
          onMouseLeave={() => {
            setProgressBar(false);
          }}
        ></div>
        <img className="bg-img" src={Frame1} alt="" />
      
        { (moreFlag) && (
          <div
  className="opportunities"
  onClick={() => {
      setTimeout(()=>{ setMoreFlag(false)},850);
      setDivFlag(false);
    }
  }
  style={{opacity:1 }}
>
  <div
    // onScroll={handleScroll}
    onClick={(e) => {
      e.stopPropagation();
    }}
    className="opp-sub-div"
    style={{ transform: divFlag ? "translateY(0vh)" : "translateY(95vh)",}}
  >
    <img className="business-text" src={BusinessText} alt="" />
    <img className="left-side" src={LeftSides} alt="" />
    <img className="right-side" src={RightSides} alt="" />
    <div className="table-div">
     
      <div className="heading-tables">
        <div className="ht-container">
        <h3>Name</h3>
       <h3>Nov-23</h3>
       <h3>Dec-23</h3>
       <h3>Jan-24</h3>
       </div>
      </div>

      <div className="top-row">
      <div className="tr-container">
         <p><span>Transactions</span><span>Amount</span></p>
         <p><span>Transactions</span><span>Amount</span></p>
         <p><span>Transactions</span><span>Amount</span></p>
         <p><span>Transactions</span><span>Amount</span></p>
         {/* <p><span>Transactions</span><span>Amount</span></p> */}
         {/* <p><span>Transactions</span><span>Amount</span></p> */}
      </div>
      </div>

      <div className="next-row">
      <div className="nr-container">
         <p>Products</p>
         <p><span>149</span><span>309326</span></p>
         <p><span>214</span><span>406829</span></p>
         <p><span>238</span><span>447422</span></p>
         {/* <p><span>240</span><span>419806</span></p> */}
         {/* <p><span>75</span><span>130455</span></p> */}
      </div>
      </div>
      <div className="next-row">
      <div className="nr-container">
         <p>Boardings</p>
         <p><span>9</span><span>11236</span></p>
         <p><span>13</span><span>21388</span></p>
         <p><span>9</span><span>12185</span></p>
         {/* <p><span>27</span><span>34699</span></p> */}
         {/* <p><span>7</span><span>58457</span></p> */}
      </div>
      </div>
      <div className="next-row">
      <div className="nr-container">
         <p>Grooming</p>
         <p><span>11</span><span>80510</span></p>
         <p><span>5</span><span>25108</span></p>
         <p><span>5</span><span>4278</span></p>
         {/* <p><span>65</span><span>11328</span></p> */}
         {/* <p><span>7</span><span>9588</span></p> */}
      </div>
      </div>
      <div className="next-row">
      <div className="nr-container">
         <p>Total</p>
         <p><span>169</span><span>401072</span></p>
         <p><span>232</span><span>453325</span></p>
         <p><span>258</span><span>463885</span></p>
         {/* <p><span>474</span><span>4,65,887</span></p> */}
         {/* <p><span>89</span><span>1,98,500</span></p> */}
      </div>
      </div>
    </div>
     
  </div>
</div>
 )}
        <div className="left-div">
          <div className="top-div">
            <h3>
              <span> Market Scope</span>
            </h3>
            {/* <h4>
              Lorem ipsum dolor, sit amet elit. Exercitationem fuga, cupiditate
              laudantium?
            </h4> */}
          </div>
          <div className="bottom-div">
            <RangeCard
              mainDetail="Globally Pet Care market is "
              censusYear="( 2011 census )"
              censusCount="$190"
              censusUnit="B"
              progressValue="75"
              ProgressBar={progressBar}
            />
            <RangeCard
              mainDetail="US is the largest $ billion market"
              censusYear=""
              censusCount="$100"
              censusUnit="B"
              progressValue="65"
              ProgressBar={progressBar}
            />
            <RangeCard
              mainDetail="India is one of the fastest growing market of grew at 17% and expected to grow at 28% CAGR"
              censusYear="2023"
              censusCount="$800"
              censusUnit="B"
              progressValue="87"
              ProgressBar={progressBar}
            />
            <RangeCard
              mainDetail="By 2030,INDIA pet care Market is expected to touch"
              censusYear=""
              censusCount="$7.5"
              censusUnit="B"
              progressValue="80"
              ProgressBar={progressBar}
            />
          </div>
          <div className="blank-div"></div>
        </div>
        <div className="right-div">
        <Traction onClick={handleSetClick} upDownEffect={upDownEffect}/>
    
          <div className="rd-mid-part">
            <div
              className="rmp-top-part"
                //  style={{transform:count === 1 ? "translateX(-60%)" : count === 2 ? "translateX(-120%)" : count === 3 ? "translateX(-300%)" : count === 4 && "translateX(-400%)"}}
            >
              <img
                src={PetPie}
                alt=""
                style={{
                    objectFit:"contain",
                  transform:
                    count === 1
                      ? "translateX(-100%) scale(0.5)"
                      : count === 2
                      ? "translateX(-200%) scale(0.5)"
                      : count === 3
                      ? "translateX(-300%) scale(0.5)"
                      : count === 4 && "translateX(-400%) scale(0.5)",
                }}
              />
              <img
                src={PCDist}
                alt=""
                style={{
                  transform:
                    count === 0
                      ? "translateX(0%) scale(0.5)"
                      : count === 1
                      ? "translateX(-100%) scale(1)"
                      : count === 2
                      ? "translateX(-200%) scale(0.5)"
                      : count === 3
                      ? "translateX(-300%) scale(0.5)"
                      : count === 4 && "translateX(-400%) scale(0.5)",
                }}
              />
              <img
                src={FastGrowMarket}
                alt=""
                style={{
                  transform:
                    count === 1
                      ? "translateX(-100%) scale(0.5)"
                      : count === 2
                      ? "translateX(-200%) scale(1)"
                      : count === 3
                      ? "translateX(-300%) scale(0.5)"
                      : count === 4 && "translateX(-400%) scale(0.5)",
                }}
              />
              <img
                src={MarketSize}
                alt=""
                style={{
                  transform:
                    count === 1
                      ? "translateX(-100%) scale(0.5)"
                      : count === 2
                      ? "translateX(-200%) scale(0.5)"
                      : count === 3
                      ? "translateX(-300%) scale(1) "
                      : count === 4 && "translateX(-400%) scale(0.5) ",
                }}
              />
              <img
                src={IndiaGrow}
                alt=""
                style={{
                  objectFit:"contain",
                  transform:
                    count === 1
                      ? "translateX(-100%) scale(0.5)"
                      : count === 2
                      ? "translateX(-200%) scale(0.5)"
                      : count === 3
                      ? "translateX(-300%) scale(0.5)"
                      : count === 4 && "translateX(-400%) scale(1)",
                }}
              />
            </div>
            <div className="rmp-bottom-part">
              <button
                className="rmp-left"
                onClick={handleLeftClick}
                disabled={count === 0 ? true : false}
                style={{opacity:count === 0 && "0.7"}}
              >
                Previous
              </button>
              <div className="rmp-circle" style={{background:count === 0 && "#FFA646",transform: count === 0 && "scale(1.5)"}} onClick={()=>{setCount(0)}}></div>
              <div className="rmp-circle" style={{background:count === 1 && "#FFA646", transform: count === 1 && "scale(1.5)"}} onClick={()=>{setCount(1)}}></div>
              <div className="rmp-circle" style={{background:count === 2 && "#FFA646", transform: count === 2 && "scale(1.5)"}} onClick={()=>{setCount(2)}}></div>
              <div className="rmp-circle" style={{background:count === 3 && "#FFA646", transform: count === 3 && "scale(1.5)"}} onClick={()=>{setCount(3)}}></div>
              <div className="rmp-circle" style={{background:count === 4 && "#FFA646", transform: count === 4 && "scale(1.5)"}} onClick={()=>{setCount(4)}}></div>
              <button
                className="rmp-right"
                onClick={handleRightClick}
                disabled={count === 4 ? true : false}
                style={{opacity:count === 4 && "0.7"}}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
