import React from "react";
import "./slideOne.scss";
import HProducts from "../../../img/h-products.png";
import HDogFood from "../../../img/h-dogfood.png";
import HArrow from "../../../img/h-arrow.png";
import HHand from "../../../img/h-hand.png";

const HomeSlideOne = () => {
  return (
    <div className="home-slide-one-wrapper">
      <img src={HArrow} alt="" className="s1-arrow" />
      <img src={HHand} alt="" className="s1-hand" />
      <div className="s1-produts">
        <img  src={HProducts} alt="" />
        <img className="second-child" src={HProducts} alt="" />
      </div>
      <div className="s1-text-wrapper">
        <h2>
          Every pet's need - <span>ONE SHOP, ONE STOP</span>!
        </h2>
        <div className="s1-btn">
          <button>SHOP NOW</button>
        </div>
      </div>
      <div className="s1-hero-img">
        <img src={HDogFood} alt="" />
      </div>
    </div>
  );
};

export default HomeSlideOne;
