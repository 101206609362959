import React, { useEffect, useState } from "react";
import "./home.scss";
import SlideOne from "./SlideOne/SlideOne";
import SlideTwo from "./SlideTwo/SlideTwo";

const Home = () => {
  const [slider, setSlider] = useState(false);
  const [slider2, setSlider2] = useState(false);
  const [slider3, setSlider3] = useState(false);
  const [slider4, setSlider4] = useState(false);
  const [slider5, setSlider5] = useState(false);
  const [flag1, setFlag1] = useState(false);
 
 

  useEffect(() => {
   let a= setTimeout(() => {
      setSlider(!slider)
    }, 4000);
    setTimeout(() => {
      setSlider2(!slider2)
    },8000);
    setTimeout(() => {
      setSlider3(!slider3)
    },12000);
    setTimeout(() => {
      setSlider4(!slider4);
      // setSlider42(!slider42);
    },16000);
    setTimeout(() => {
      setSlider5(!slider5);
    },18100);
    setTimeout(()=>{
      setSlider(false);
      setSlider2(false);
      setSlider3(false);
      setSlider4(false);
      setSlider5(false);
      setFlag1(!flag1);
    },18500)
    
    return ()=>{
      clearTimeout(a);
    }
  },[flag1])

  // console.log(slider,slider2,slider3,slider4,"dkjdbjgbf");
 

 
  return (
    <div className="homie">
    <div className="home-wrapper1">
      <div className="slideOne1" 
      style={{
        transform:!slider4 ? (!slider3 ? (slider && !slider2 ? "translateY(-100%)" : slider && slider2 && "translateY(-200%)") : "translateY(100%)") : "translateY(0%)",
        transition:slider3 && !slider4 && "0s",
      }}
      >
        <SlideOne />
      </div>
      <div className="slideTwo1" 
      style={{
        transform:!slider4 ? (!slider3 ? (slider && !slider2 ? "translateY(0%)" : slider && slider2 && "translateY(-100%)") : "translateY(200%)") : "translateY(100vh)",
        transition:slider3 && !slider4 && "0s",
        // zIndex:slider3 && -1
      }}
      >
      <SlideTwo/>
      </div>
    </div>

     <div className="home-wrapper2">
      <div className="slideOne2" 
      style={{ transform: !slider4 ? (!slider3 ? ((slider && !slider2) ? "translateY(100vh)" : (slider && slider2) && "translateY(0vh)") : "translateY(-100vh)"):"translateY(200vh)",
      transition:slider4 && "0s"}}
      >
      <SlideOne />
      </div>
      <div className="slideTwo2" 
      style={{
        transform: !slider5 ? (!slider4 ? (!slider3 ? ((slider && !slider2) ? "translateY(200vh)" : (slider && slider2) && "translateY(100vh)") : "translateY(0vh)") : "translateY(-100vh)") : "translateY(300vh)",
        transition:slider5 && "0s",
      }}
      >
      <SlideTwo />
      </div>
    </div>
    </div>
  );
};

export default Home;
