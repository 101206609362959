import React, { useState } from "react";
import "./pms.scss";
import { Tilt } from "react-tilt";
import PmsToe from "../../img/pms-toe.png";
import PmsTop from "../../img/pms-top.png";
import PmsBottom from "../../img/pms-bottom.png";
import PmsRight from "../../img/pms-right.png";
import MioHand from "../../img/mio-hand.png";
import MioHandWhite from "../../img/h-hand.png";
import Reviews from "./Reviews/Reviews";

const Pms = () => {
  const [mioSlide, setMioSlide] = useState(false);
  const fsData = [
    {
      id: 1,
      value: "$7M",
      raise: "was raised by Just Dogs from The Sixth Sense Venture",
    },
    {
      id: 2,
      value: "$170M",
      raise: "investments were made in the Indian pet care market last year",
    },
    {
      id: 3,
      value: "$60M",
      raise:
        "was secured by HUFT, led by Verlinvest and Sequoia Capital India     ",
    },
    {
      id: 4,
      value: "$37M",
      raise: "funding round was dosed by Supertails, led by RPSG Capital      ",
    },
    {
      id: 5,
      value: "$15M",
      raise: "funding round was dosed by Supertails, led by RPSG Capital ",
    },
    {
      id: 6,
      value: "",
      raise:
        "Notable Angel Investors: Cred's Kunal Shah, Mamaearth's Varun Alagh, ShareChat's Farid Ahsan, Lachy Groom, Utsav Somani, Chaayos Nitin Saluja and Oyo's Ritesh Agarwal      ",
    },
    {
      id: 7,
      value: "",
      raise:
        "Various active VCs in the sector include Anthill Ventures, Panthera Peak Ventures, Info Edge, and Point One Capital      ",
    },
  ];

  const SsData = [
    {
      id: 1,
      val: "70%",
      mshare: " total Market share",
    },
    {
      id: 2,
      val: "17.08%",
      mshare: "CAGR Growth",
    },
  ];
  const SsData2 = [
    {
      id: 1,
      val: "$0.85 ",
      ttext: "Stands at",
      extra: "BILLION",
      btn: "by 2024",
    },
    {
      id: 2,
      val: "$1.87 ",
      ttext: "To Reach",
      extra: "BILLION",
      btn: "by 2029",
    },
  ];
  const defaultOptions = {
    reverse: false, // reverse the tilt direction
    max: 35, // max tilt rotation (degrees)
    perspective: 1000, // Transform perspective, the lower the more extreme the tilt gets.
    scale: 1.1, // 2 = 200%, 1.5 = 150%, etc..
    speed: 1000, // Speed of the enter/exit transition
    transition: true, // Set a transition on enter/exit.
    axis: null, // What axis should be disabled. Can be X or Y.
    reset: true, // If the tilt effect has to be reset on exit.
    easing: "cubic-bezier(.03,.98,.52,.99)", // Easing on enter/exit.
  };

  const handleScroll = (e) => {
    let height = window.innerHeight;

    if (e.target.scrollTop >= height + 80) {
      setMioSlide(true);
    } else {
      setMioSlide(false);
    }
  };
  return (
    <div className="pms-main" onScroll={handleScroll}>
      <div className="pms-top">
        <img src={PmsToe} alt="" className="pms-toe" />
        <div className="pms-text">
          <h2>
            Pet Market <br /> Statistics: Insights <br />
            into a Thriving <br /> Industry
          </h2>
        </div>
        <div className="spread-div"></div>
        <Tilt options={defaultOptions} className="tilt-right">
          <div className="pms-right">
            {/* <img src={PmsRight} alt="" /> */}
            <div className="pms-card-heading">
              INDIA’S PET CARE <br />{" "}
              <span>
                PRODUCT MARKET <br /> 2024
              </span>
            </div>
            <ul className="pms-list">
              <li>
                MARKET SHARE - <b> 20%</b>
              </li>
              <li>
                Estimated Value - <b>$ 0.75 Bn</b>
              </li>
              <li>
                InDUSTRY IS EXPECTED TO GROW AT A <b>CAGR</b> OF - <b>23.7%</b>
              </li>
            </ul>
          </div>
        </Tilt>
        <Tilt options={defaultOptions} className="tilt-top">
          <div className="pms-top">
            <div className="pms-card-heading">
              INDIA’S PET <br /> <span>POPULATION </span>
            </div>
            <ul className="pms-list">
              <li>
                MARKET SHARE - <b> 20%</b>
              </li>
              <li>
                Estimated Value - <b>$ 0.75 Bn</b>
              </li>
              <li>
                InDUSTRY IS EXPECTED TO GROW AT A <b>CAGR</b> OF - <b>23.7%</b>
              </li>
            </ul>
          </div>
        </Tilt>
        <Tilt options={defaultOptions} className="tilt-bottom">
          <div className="pms-bottom">
            <div className="pms-card-heading">
              INDIA’S VETERINARY <br /> HEALTHCARE & SERVICE{" "}
              <span>
                <br /> MARKET STATS{" "}
              </span>
            </div>
            <ul className="pms-list">
              <li>
                MARKET SHARE - <b> 20%</b>
              </li>
              <li>
                Estimated Value - <b>$ 0.75 Bn</b>
              </li>
              <li>
                InDUSTRY IS EXPECTED TO GROW AT A <b>CAGR</b> OF - <b>23.7%</b>
              </li>
            </ul>
          </div>
        </Tilt>
      </div>

      <div
        className="mio-main"
        // style={{
        //   transform: progress2 < pageProgress && "translateY(-35%)",
        // }}
      >
        <div className="mio-left">
          <img src={MioHand} alt="" className="mio-hand" />
          <div className="mioleft-text">
            <h2>INDIAN</h2>
            <span>pet care economy set to</span>
            <div className="mio-btn">$3 Billion</div>
            <p>MARK BY 2025</p>
          </div>
        </div>

        <div className="mio-right">
          <img src={MioHandWhite} alt="" className="hand1" />
          <img src={MioHandWhite} alt="" className="hand2" />
          <div
            className="mio-right-circle"
            style={{
              backgroundColor: mioSlide && "#6fdabc",
            }}
          ></div>
          <div className="center-blur"></div>
          <div
            className="mr-first-slide"
            style={{
              transition: "1s ease-out",
              transform: mioSlide && "translateY(-100%)",
            }}
          >
            <div className="mio-right-heading">
              <h3>
                {" "}
                India’s Pet Care <br /> <span>Market’s Investment Outlook</span>
              </h3>
            </div>
            <div className="mio-right-bottom">
              {fsData.map((item) => {
                return (
                  <Tilt options={defaultOptions} className="mio-tilt">
                    <div className="mio-card" key={item.id}>
                      <h4>{item.value}</h4>
                      <p
                        style={{
                          textAlign:
                            item.id === 6 && item.id === 7 ? "left" : "",
                        }}
                      >
                        {item.raise}
                      </p>
                    </div>
                  </Tilt>
                );
              })}
            </div>
          </div>
          <div
            className="mr-second-slide"
            style={{
              transform: mioSlide && "translateY(0%)",
            }}
          >
            <div className="mio-right-heading">
              <h3>
                {" "}
                India’s Pet Care <br /> <span>Food Market STATS 2024</span>
              </h3>
            </div>
            <div className="mio-right-bottom">
              {SsData.map((item) => {
                return (
                  <Tilt options={defaultOptions} className="mio-tilt">
                    <div className="mio-card" key={item.id}>
                      <h4>
                        <span
                          style={{
                            fontSize: item.id === 2 && "1.5vw",
                          }}
                        >
                          {item.val}
                        </span>
                      </h4>
                      <p>{item.mshare}</p>
                    </div>
                  </Tilt>
                );
              })}
            </div>
            <div className="mr-bottom2">
              {SsData2.map((item) => {
                return (
                  <Tilt options={defaultOptions} className="mio-tilt">
                  <div className="mio-card" key={item.id}>
                    <div className="mr-circle">
                      <span className="span-extra"></span>
                      <img src={MioHandWhite} alt="" className="mr-hand" />
                    </div>
                    <span className="span-two">{item.ttext}</span>
                    <h4>{item.val}</h4>

                    <div className="mr-text">
                      {item.extra} <span>{item.btn}</span>
                    </div>
                  </div>
                  </Tilt>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="reviews-bottom">
        <Reviews />
      </div>
    </div>
  );
};

export default Pms;
