const thirdPDCompData=[
    {
        heading:"PET PRODUCTS & PET SERVICES",
        para1:"Petzzing is an",
        para2:"digital platform available across website and app offering products and services such as grooming, boarding, vet consultation services."
    },
    {
        heading:"QC & KYC ",
        para1:"All services partners undergo a QC check & a KYC check by petzzing so there is a  ",
        para2:"which customers can avail.",
    }, {
        heading:"Loyalty ",
        // para:"  Your renting reward zone, assisting you with negotiating more."
        para1: "Petzzing has this unique offering of loyalty rewards for every purchase of products and services and which can be  ",
        para2: "immediately against the next purchase of products or services."

    }, {
        heading:" EDUCATING PET PARENTS",
        para1:"Petzzing also has a knowledge sharing platform which offers various ",
        para2:"on the same platform for educating pet parents."
    },
];
export default thirdPDCompData;