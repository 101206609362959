import React from 'react'
import "./Traction.scss";
import TouchAppIcon from "@mui/icons-material/TouchApp";

const Traction = ({onClick,upDownEffect}) => {
  return (
    <div className="more-div">
    <div className="stroke" />
    <button
      className="more-btn"
      onClick={onClick}
    >
      FINANCIAL SHEET <span className="tap-icon-div-sevens">
                        <TouchAppIcon
                          className="tap-icon-sevens"
                          style={{
                            animation: upDownEffect && "tapIcon-KeyFrame-Sevens 0.2s",
                          }} />
            </span>
    </button>
   
  </div>
  )
}

export default Traction
