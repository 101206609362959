import { ImageServer } from "../../img/imageServer";

import Frame1 from "../../img/home-big.png";
import Frame01 from "../../img/home-small.png";
import Frame2 from "../../img/problem-big.png";
import Frame02 from "../../img/problem-small.png";
import Frame3 from "../../img/solution-big.png";
import Frame03 from "../../img/solution-small.png";
import Frame4 from "../../img/usp-big.png";
import Frame04 from "../../img/usp-small.png";
import Frame6 from "../../img/flow-big.png";
import Frame06 from "../../img/flow-small.png";
import Frame7 from "../../img/scope-big.png";
import Frame07 from "../../img/scope-small.png";
import Frame9 from "../../img/marketing-big.png";
import Frame09 from "../../img/marketing-small.png";
import Frame11 from "../../img/bm-big.png";
import Frame011 from "../../img/bm-small.png";
import Frame12 from "../../img/traction-big.png";
import Frame012 from "../../img/traction-small.png";
import Frame13 from "../../img/thankyou-big.png";
import Frame013 from "../../img/thankyou-small.png";
import Frame14 from "../../img/stats-big.png";
import Frame014 from "../../img/stats-small.png";

// const Frame01 = "https://rentblob.blob.core.windows.net/turant/home.png"
// const Frame1 = "https://rentblob.blob.core.windows.net/turant/home-big.png"
// const Frame02 = "https://rentblob.blob.core.windows.net/turant/problem.png"
// const Frame2 = "https://rentblob.blob.core.windows.net/turant/problem-big.png"
// const Frame03 = "https://rentblob.blob.core.windows.net/turant/solution.png"
// const Frame3 = "https://rentblob.blob.core.windows.net/turant/solution-big.png"
// const Frame04 = "https://rentblob.blob.core.windows.net/turant/usp.png"
// const Frame4 = "https://rentblob.blob.core.windows.net/turant/usp-big.png"
// const Frame05 = "https://rentblob.blob.core.windows.net/turant/marketing.png"
// const Frame5 = "https://rentblob.blob.core.windows.net/turant/marketing-big.png"
// const Frame06 = "https://rentblob.blob.core.windows.net/turant/flow.png"
// const Frame6 = "https://rentblob.blob.core.windows.net/turant/flow-big.png"
// const Frame07 = "https://rentblob.blob.core.windows.net/turant/scope.png"
// const Frame7 = "https://rentblob.blob.core.windows.net/turant/scope-big.png"
// const Frame08 = "https://rentblob.blob.core.windows.net/turant/gtm.png"
// const Frame8 = "https://rentblob.blob.core.windows.net/turant/gtm-big.png"
// const Frame09 = "https://rentblob.blob.core.windows.net/turant/diff.png"
// const Frame9 = "https://rentblob.blob.core.windows.net/turant/diff-big.png"
// const Frame010 = "https://rentblob.blob.core.windows.net/turant/comp.png"
// const Frame10 = "https://rentblob.blob.core.windows.net/turant/comp-big.png"
// const Frame011 = "https://rentblob.blob.core.windows.net/turant/bm.png"
// const Frame11 = "https://rentblob.blob.core.windows.net/turant/bm-big.png"
// const Frame012 = "https://rentblob.blob.core.windows.net/turant/rm.png"
// const Frame12 = "https://rentblob.blob.core.windows.net/turant/rm-big.png"
// const Frame013 = "https://rentblob.blob.core.windows.net/turant/ask.png"
// const Frame13 = "https://rentblob.blob.core.windows.net/turant/ask-big.png"
// const Frame014 = "https://rentblob.blob.core.windows.net/turant/team.png"
// const Frame14 = "https://rentblob.blob.core.windows.net/turant/team-big.png"
// const Frame015 = "https://rentblob.blob.core.windows.net/turant/thankyou.png"
// const Frame15 = "https://rentblob.blob.core.windows.net/turant/thankyou-big.png"

export const navigatingBarData = [
  {
    imgURL1: Frame01,
    imgURL2: Frame1,
    name: "HOME",
    shortName: "HOME",
  },
  {
    imgURL1: Frame02,
    imgURL2: Frame2,
    name: "PROBLEM",
    shortName: "PROBLEM",
  },
  {
    imgURL1: Frame03,
    imgURL2: Frame3,
    name: "SOLUTION",
    shortName: "SOLUTION",
  },
  {
    imgURL1: Frame04,
    imgURL2: Frame4,
    name: "USP",
    shortName: "USP",
  },
  // {
  //     imgURL1: Frame05,
  //     imgURL2: Frame5,
  //     name: "VIDEO",
  //     shortName: "VIDEO"

  // },
  {
    imgURL1: Frame012,
    imgURL2: Frame12,
    name: "TRACTION",
    shortName: "TRACTION",
  },
  {
    imgURL1: Frame06,
    imgURL2: Frame6,
    name: "APP FLOW",
    shortName: "FLOW",
  },
  {
    imgURL1: Frame014,
    imgURL2: Frame14,
    name: "MARKET STATS",
    shortName: "M. STATS",
  },
  //   {
  //     imgURL1: Frame014,
  //     imgURL2: Frame14,
  //     name: "MARKET STATS",
  //     shortName: "M. STATS",

  // },

  {
    imgURL1: Frame07,
    imgURL2: Frame7,
    name: "MARKET SCOPE",
    shortName: "SCOPE",
  },

  {
    imgURL1: Frame09,
    imgURL2: Frame9,
    name: "MARKETING",
    shortName: "MARKETING",
  },
  // {
  //     imgURL1: Frame010,
  //     imgURL2: Frame10,
  //     name: "COMPETITION",
  //     shortName: "COMP"

  // },
  // {
  //     imgURL1: Frame016,
  //     imgURL2: Frame16,
  //     name: "COMPETITIVE ADVANTAGE",
  //     shortName: "COMP AD"

  // },
  {
    imgURL1: Frame011,
    imgURL2: Frame11,
    name: "BUSINESS MODEL",
    shortName: "B.M.",
  },
  // {
  //     imgURL1: Frame017,
  //     imgURL2: Frame17,
  //     name: "DATA MONETIZATION",
  //     shortName: "DATA PLAY"

  // },
  // {
  //     imgURL1: Frame012,
  //     imgURL2: Frame12,
  //     name: "ROADMAP",
  //     shortName: "R.M."

  // },
  // {
  //     imgURL1: Frame013,
  //     imgURL2: Frame13,
  //     name: "OUR ASK",
  //     shortName: "ASK"

  // },

  {
    imgURL1: Frame013,
    imgURL2: Frame13,
    name: "THANK YOU",
    shortName: "THANK YOU",
  },
];
