import React, { useState, useEffect } from "react";
import "./secondPD-comp.scss";
import SecondCompData from "./secondCompData";
import CardContainerComp from "./card-container-comp";
import TouchAppIcon from '@mui/icons-material/TouchApp';
import PetBgImg from "../../img/girlDog.png";
import DogLogo from "../../img/dogLogo.png"

export default function SecondPDComp() {

    const [upDownEffect, setUpDownEffect] = useState(false);

    useEffect(() => {
        
       const timeOutId= setTimeout(() => {
            setUpDownEffect(!upDownEffect)
        }, 1000)
 
    return ()=>{clearTimeout(timeOutId)}
    }, [upDownEffect])

    const generateCommonStyle = (val,upDownEffect) => {
        return {
          transform: itemId === val && `scale(${1.1})`,
          marginLeft: itemId === val && "2%",
          fontWeight: itemId === val && "600",
          color: itemId === val && "#FF4D4D",
          cursor: itemId !== val ? "pointer" : "default",
          borderBottom: itemId === val && "none",
          animation: itemId === val && upDownEffect && "NavBar-keyframe 0.2s",
        };
      };

    const [itemId, setItemId] = useState(0)

    function handlePrevClick() {
        itemId !== 0 && setItemId(itemId - 1);
    }
    function handleNextClick() {
        itemId !== 2 && setItemId(itemId + 1)
    }

    return (
        <>
            <div className="secondPD-comp-main-container">
                <img className="pet-bg-img" src={PetBgImg} alt="" />

                 <div className="top-div">
                    <div className="ractangle-div">
                        <div className="shape1"><h3>PROBLEM</h3></div>
                    </div>
                    <div className="heading-div">
                        {/* <h1>Problem</h1> */}
                        <h3>
                        No Single Centralized Platform For Both <br />
                        <p className="para-holders">
                            <span>Products</span>
                            <span>&</span>
                            <span>Services </span>
                        </p>
                        </h3>

                    </div>
                </div>
                <div className="left-div">
                    <div className="focus-div"
                        style={{top: itemId === 0 ? "10%" : itemId === 1 ? "40%" : itemId === 2 && "70%"}}
                    ></div>
                    <div className="landlord-div">
                        <div className="tap-icon-div" >
                            <TouchAppIcon className="tap-icon"
                                style={{animation: upDownEffect && "tapIcon-KeyFrame 0.2s" }}
                            />
                        </div>
                        <h3
                            onClick={()=>{setItemId(0)}}
                            style={generateCommonStyle(0,upDownEffect)}      
                        >Point 1</h3>
                    </div>
                    {/* <div className="tenant-div">
                        <div className="tap-icon-div" style={{visibility: itemId === 1 && "hidden"}}>
                            <TouchAppIcon className="tap-icon"
                                style={{animation: upDownEffect && "tapIcon-KeyFrame 0.2s"}}
                            />
                        </div>
                        <h3
                            onClick={()=>{setItemId(1)}}
                            style={generateCommonStyle(1,upDownEffect)}  
                        >Tenant</h3>
                    </div> */}
                    {/* <div className="broker-div">
                        <div className="tap-icon-div" style={{visibility: itemId === 2 && "hidden"}}>
                            <TouchAppIcon className="tap-icon" style={{animation: upDownEffect && "tapIcon-KeyFrame 0.2s"}}/>
                        </div>
                        <h3
                            onClick={()=>{setItemId(2)}}
                            style={generateCommonStyle(2,upDownEffect)}
                        >Broker</h3>
                    </div> */}
                </div>
                {/* <div className="right-div">
                    {SecondCompData.map((item, index) => {
                        return (
                            <div key={index}>
                                { itemId === index && <CardContainerComp Item={item.property}/>}
                            </div>
                        );
                    })}
                </div> */}
                <div className="pet-right-div">
                    <div className="pet-top-part">
                     <img className="dog-logo-img" src={DogLogo} alt="" />
                     <h3><span style={{color:"#FF8603"}}>TROUBLE</span> with your pet?</h3>
                    </div>
                    <div className="pet-bottom-part">
                     <p>Pets share similar needs with humans, and each pet requires essential services such as boarding, grooming, training, veterinary care, as well as access to food and accessories. Pet owners often face challenges in finding affordable and reliable service providers because the pet industry remains largely unorganized and fragmented.</p>
                    </div>
                </div>
            </div>
        </>
    );
}