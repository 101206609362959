import React, { useState, useEffect } from "react";
import "./sixPD-comp.scss";
import { listData } from "./sixPDCompData";
import TouchAppIcon from '@mui/icons-material/TouchApp';

//-----------------Sub Comp-------------------------------------
import CompOne from "./Nested-Comp/Comp-One/comp-one";
import CompTwo from "./Nested-Comp/Comp-Two/comp-two";
import CompThree from "./Nested-Comp/Comp-Three/comp-three";
import CompFour from "./Nested-Comp/Comp-Four/comp-four";
import CompFive from "./Nested-Comp/Comp-Five/comp-five";
import CompSix from "./Nested-Comp/Comp-Six/comp-six";
// import CompSeven from "./Nested-Comp/Comp-Seven/comp-seven";
// import CompEight from "./Nested-Comp/Comp-Eight/comp-eight";
// import CompNine from "./Nested-Comp/Comp-Nine/comp-nine";


//--------------------------------------------------------------

const BgImg = "https://rentblob.blob.core.windows.net/turant/bgImg6.png";
// import Logo from "../../img/sixth-comp-img/logo.png";


// const Logo = "https://rentblob.blob.core.windows.net/pitch/img/logo3.svg";

export default function SixPDComp() {
    const [listId, setListId] = useState(0);
    // eslint-disable-next-line
    const [hoverID, setHoverID] = useState(0);
    const [upDownEffect, setUpDownEffect] = useState(false);

    function handleMouseEnterEvent(id) {
        setListId(id)
        setHoverID(id + 1)
    }
    function handleMouseLeaveEvent() {
        setHoverID(0);
      }

    useEffect(() => {
      const timeOutId=setTimeout(() => {setUpDownEffect(!upDownEffect)}, 800);

      return ()=>{clearTimeout(timeOutId)};
    }, [upDownEffect])

    return (
        <>
            <div className="sixPD-comp-main-container">
            <img src={BgImg} alt="" />

<div className="left-div">
  <div className="top-div">
    <div className="heading-div">
        <h1><span>PRODUCT</span> highlights</h1>
        <hr />
    </div>
  </div>
  <div className="bottom-div">
    <div className="list-div">
      {listData.map((item, index) => {
        return (
          <div key={index} className="properties-para">
            <p
              onMouseOver={() => {
                handleMouseEnterEvent(index);
              }}
              onMouseLeave={handleMouseLeaveEvent}
              style={{
                // color: listId === index && "#ff4d4d",
                // transform: listId === index && `scale(${1.08})`,
                fontWeight: listId === index && "500",
                top:index === 1 ? `calc(${index * 19}%)` : index === 2 ? `calc(${index * 18}%)` : index === 4 ? `calc(${index * 16}%)` : `calc(${index * 17}%)`,
                // textTransform: listId === index && "capitalize",
              }}
            >
               <span className="dot" style={{opacity:listId === index && 1}}></span>
               
              <span className="divs-container">
              {index === 2 ? <><span style={{
                         color: listId === index && "#EC8101",
                         textTransform: listId === index && "uppercase"
                    }}>Pet shop</span><span style={{}}>{item.listPara}</span></> : index === 3 ? <><span style={{
                        color: listId === index && "#EC8101",
                        textTransform: listId === index && "uppercase"
                   }}>Share, learn and connect </span><span style={{}}>{item.listPara}</span></>: index === 5 ?  <><span style={{
                    color: listId === index && "#EC8101",
                    textTransform: listId === index && "uppercase"
               }}>4.3 Rating </span><span style={{}}>{item.listPara}</span></> : <span style={{}}>{item.listPara}</span>}
              {index == 0 ? (
                <span style={{ color: listId === index && "#EC8101",textTransform: listId === index && "uppercase" }}>
                  mobile app and website
                </span>
              ) : (
                index == 1 ? (
                  <>
                    <span style={{
                         color: listId === index && "#EC8101",
                         textTransform: listId === index && "uppercase"
                    }}>
                       pet services </span> 
                       <span>to meet your furry friend's every need</span>
                       
                    </>
                ) :
                index === 4 && (
                    <>
                    {/* <span style={{ fontWeight : listId === index && "bold",fontStyle: listId === index && "italic"}}>Fixed Issues Related To </span> */}
                    <span style={{ color: listId === index && "#EC8101",
                         textTransform: listId === index && "uppercase" }}> Apple Store, Google Playstore </span>
                         <span> and support all known browsers </span>
                    </>
                )
              )}
              </span>
              <span
                className="tap-icon-div"
                style={{
                  visibility: listId === index && "hidden",
                }}
              >
                <TouchAppIcon
                
                  className="tap-icon"
                  style={{
                    animation: upDownEffect && "tapIcon-KeyFrame 0.2s",
                  }}
                />
              </span>
            </p>
          </div>
        );
      })}
    </div>
  </div>
</div>
                <div className="right-div">
                    {
                        (listId + 1) === 1 ? <CompOne HoverID={listId + 1} /> :
                            (listId + 1) === 2 ? <CompTwo HoverID={listId + 1} /> :
                                (listId + 1) === 3 ? <CompThree HoverID={listId + 1} /> :
                                    (listId + 1) === 4 ? <CompFour HoverID={listId + 1} /> :
                                        (listId + 1) === 5 ? <CompFive HoverID={listId + 1} /> :
                                            (listId + 1) === 6 && <CompSix HoverID={listId + 1} /> 
                                            // :
                                            //     (listId + 1) === 7 ? <CompSeven HoverID={listId + 1} /> :
                                            //         (listId + 1) === 8 ? <CompEight HoverID={listId + 1} /> :
                                            //             (listId + 1) === 9 && <CompNine HoverID={listId + 1} />
                    }
                </div>
            </div>
        </>
    );
}