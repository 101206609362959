import React, { useState, useEffect } from "react";
import "./businessModel.scss";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import BsLogo from "../../img/bs-1.png";
import BsCenter2 from "../../img/bs-2.png";
import BsCenter3 from "../../img/bs-3.png";
import BsCenter4 from "../../img/bs-4.png";
import RectangleBarRight from "../../img/right-frame.png"
import RectangleBarLeft from "../../img/left-frame.png"

const RightBottomHr =
  "https://rentblob.blob.core.windows.net/turant/right-bottom-hr.png";
const TopRightHr =
  "https://rentblob.blob.core.windows.net/turant/right-top-hr.png";
const TopLeftHr = "https://rentblob.blob.core.windows.net/turant/left-hr.png";
// const RectangleBarLeft =
//   "https://rentblob.blob.core.windows.net/turant/left-frame.png";
// const RectangleBarRight =
//   "https://rentblob.blob.core.windows.net/turant/right-frame.png";

export default function BusinessModel() {
  const [bsHover, setBSHover] = useState(false);
  const [isIconHover, setIsIconHover] = useState(false);

  const [upDownEffect, setUpDownEffect] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setUpDownEffect((prev) => {
        return !prev;
      });
    }, 1000);
  }, [upDownEffect]);

  useEffect(() => {
    setTimeout(() => {
      setIsIconHover(!isIconHover);
    }, 800);
  }, [isIconHover]);

  return (
    <>
      <div
        className="marketing-comp-main-container"
        onMouseEnter={() => setBSHover(true)}
      >
        <h1 className="heading">
          BUSINESS <br />
          <span>Model</span>
        </h1>
        {/* ---------------- */}

        <div
          className="bs-touch-icon"
          style={{
            opacity: bsHover ? "0" : "1",
          }}
        >
          <TouchAppIcon
            style={{
              animation: isIconHover && "tapIconKeyFrame 0.2s",
              width: "500px",
            }}
          />
        </div>

        <div
          className="circle-div"
          style={{
            transform: bsHover && "scale(1)",
          }}
        >
          <div
            className="small-circle-div"
            style={{
              transform: bsHover && "scale(0)",
            }}
          ></div>
          <div className="img-div-one">
            {/* <img src={OrangeImg} alt="" /> */}
            <img className="img-two" src={BsLogo} alt="" />
          </div>
          <div className="img-div-two">
            <img src={BsCenter2} alt="" />
          </div>
          <div className="img-div-three">
            <img src={BsCenter3} alt="" />
          </div>
          <div className="img-div-four">
            <img src={BsCenter4} alt="" />
          </div>
        </div>
        {/* -------------------------------- */}
        <div className="text-section-wrapper">
          <div className="left-text">
            <p
              style={{
                transform: bsHover && "translate(0%)",
              }}
            >
              Online<span className="serv-span"> SERVICES</span>
            </p>
            <div
              className="extra-det"
              style={{
                transform: bsHover && "translate(0%)",
              }}
            >
              <span className="light-span">
                Gross Margin : <b>12.5%</b>
              </span>
              <br />
              <span className="light-span">
                % of Revenue: <b>12.5%</b>
              </span>
            </div>
            <img
              src={RectangleBarLeft}
              alt=""
              className="rect-bar"
              style={{
                transform: bsHover && "translate(0%)",
              }}
            />
            <img
              src={TopLeftHr}
              alt=""
              className="dotted-connector"
              style={{
                opacity: bsHover && "1",
              }}
            />
          </div>
          <div className="right-top-text">
            <p
              style={{
                transform: bsHover && "translate(0%)",
              }}
            >
              Online<span className="serv-span"> PET SHOP</span>
            </p>
            <div
              className="extra-det"
              style={{
                transform: bsHover && "translate(0%)",
              }}
            >
              <span className="light-span">
                Gross Margin : <b> 8% To 10% </b>
              </span>
              <br />
              <span className="light-span">
                % of Revenue: <b>25% To 30%</b>
              </span>
            </div>
            <img
              src={RectangleBarRight}
              alt=""
              className="right-rect-bar"
              style={{
                transform: bsHover && "translate(0%)",
              }}
            />
            <img
              src={TopRightHr}
              alt=""
              className="right-dotted-connector"
              style={{
                opacity: bsHover && 1,
              }}
            />
          </div>
          <div className="right-bottom-text">
            <p
              style={{
                transform: bsHover && "translate(0%)",
              }}
            >
              Online<span className="serv-span"> PET SHOP</span> with Inventory{" "}
              <br />
              Led Model & Launch Privated Label
            </p>
            <div
              className="extra-det"
              style={{
                transform: bsHover && "translate(0%)",
              }}
            >
              <span className="light-span">
                Gross Margin : <b>25% To 30%</b>
              </span>
              <br />
              <span className="light-span">
                % of Revenue: <b>72%</b>
              </span>
            </div>
            <img
              src={RectangleBarRight}
              alt=""
              className="right-rect-bar"
              style={{
                transform: bsHover && "translate(0%)",
              }}
            />
            <img
              src={RightBottomHr}
              alt=""
              className="right-dotted-connector"
              style={{
                opacity: bsHover && 1,
              }}
            />
          </div>
        </div>

        {/* ------------------------------------------------------------------- */}
        <div className="shape-one"></div>
        <div className="shape-two"></div>
        <div className="shape-three"></div>
        <div className="shape-four"></div>
      </div>
    </>
  );
}
