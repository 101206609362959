import React, { useEffect, useState } from "react";
import "./mCompOne.scss";
import DmImgOne from "../../../img/marketing1.png";
import DmImgTwo from "../../../img/marketing2.png";
import DmImgThree from "../../../img/marketing3.png";
import DmImgFour from "../../../img/marketing4.png";
import DmImgFive from "../../../img/marketing5.jpg";
import DmImgSix from "../../../img/marketing6.jpg";
import DmImgSeven from "../../../img/marketing7.jpg";
import DmImgEight from "../../../img/marketing8.jpg";
import DmImgNine from "../../../img/marketing9.jpg";
import DmImgTen from "../../../img/marketing10.jpg";

const MCompOne = ({ toggleMarketing }) => {
  const [dmAnim, setDmAnim] = useState(0);
  const dmImgData = [
    {
      id: 1,
      mediaOne: DmImgOne,
      mediaTwo: DmImgTwo,
    },
    {
      id: 2,
      mediaOne: DmImgThree,
      mediaTwo: DmImgFour,
    },
    {
      id: 3,
      mediaOne: DmImgFive,
      mediaTwo: DmImgSix,
    },
    {
      id: 4,
      mediaOne: DmImgSeven,
      mediaTwo: DmImgEight,
    },
    {
      id: 5,
      mediaOne: DmImgNine,
      mediaTwo: DmImgTen,
    },
  ];



  useEffect(() => {
    setTimeout(() => {
      if (dmAnim >= 5) {
        setDmAnim(1);
      } else {
        setDmAnim(dmAnim + 1);
      }
    }, 5000);
  }, [dmAnim]);

  return (
    <div
      className="mCompOne"
      style={{
        transform: toggleMarketing[0] && "translateY(0%)",
      }}
    >
      <div className="mComOne-wrapper">
      {/* <div className="mCompCut"></div>
      <div className="mCompCut2"></div> */}
        
        {dmImgData.map((item) => {
          return (
            <div
              className="img-card"
              key={item.id}
              style={{
                transform:
                  dmAnim === 1
                    ? "translate(0%)"
                    : dmAnim === 2
                    ? "translate(-108%)"
                    : dmAnim === 3
                    ? "translate(-216%)"
                    : dmAnim === 4
                    ? "translate(-324%)"
                    : dmAnim === 5
                    ? "translate(-432%)"
                    : "",
              }}
            >
              <img src={item.mediaOne} alt="" />
              <img src={item.mediaTwo} alt="" />
            </div>
          );
        })}
      </div>
      <div className="mCompOne-dots">
        {dmImgData.map((item) => {
          return (
            <p
              key={item.id}
              style={{
                backgroundColor: item.id === dmAnim && "#ff8603",
              }}
              // onClick={() => setDmAnim(2)}
            ></p>
          );
        })}
      </div>
    </div>
  );
};

export default MCompOne;
