import React, { useEffect, useState } from "react";
import "./marketing.scss";
import MarketingRightBg from "../../img/marketing-right-bg.png";
import MHome from "./MHome/MHome";
import MCompOne from "./MCompOne/MCompOne";
import MCompTwo from "./MCompTwo/MCompTwo";

const Marketing = () => {
  const [dogAnim, setDogAnim] = useState(false);
  const [dogOne, setDogOne] = useState(false);
  const [dogTwo, setDogTwo] = useState(false);
  const [dogThree, setDogThree] = useState(false);
  const [hideMarketingHome, setHideMarketingHome] = useState(false);
  const [toggleMarketing, setToggleMarketing] = useState([false, false]);

  const handleMarketing = (id) => {
    if (id === 1) {
      setToggleMarketing([true, false]);
      setHideMarketingHome(true);
    } else if (id === 2) {
      setToggleMarketing([false, true]);
      setHideMarketingHome(true);
    }
  };

  useEffect(() => {
    if (dogAnim) {
      setTimeout(() => {
        setDogOne(true);
        setDogTwo(false);
        setDogThree(false);
      }, 300);
    }
    if (dogAnim) {
      setTimeout(() => {
        setDogOne(false);
        setDogTwo(true);
        setDogThree(false);
      }, 1100);
    }
    if (dogAnim) {
      setTimeout(() => {
        setDogOne(false);
        setDogTwo(false);
        setDogThree(true);
      }, 1900);
    }
    if (dogAnim) {
      setTimeout(() => {
        setDogOne(false);
        setDogTwo(true);
        setDogThree(false);
      }, 2700);
    }
    if (dogAnim) {
      setTimeout(() => {
        setHideMarketingHome(true);
        setToggleMarketing([true, false]);
      }, 3500);
    }
  }, [dogAnim]);

  return (
    <div className="marketing-main" onMouseEnter={() => setDogAnim(true)}>
      <div className="top-left-box">
        <div className="box-wrapper" />
        <div className="text-wrapper">Marketing</div>
      </div>
      <div className="bottom-left-box" />
      <div
        className="marketing-content"
        style={{
          transform: hideMarketingHome  && "translateY(110%)",
        }}
      >
        <MHome dogOne={dogOne} dogTwo={dogTwo} dogThree={dogThree} />
      </div>
      <div className="digitak-marketing">
        <MCompOne toggleMarketing={toggleMarketing} />
        <MCompTwo toggleMarketing={toggleMarketing}/>
      </div>

      {/* ------------------------- Right Bar */}
      <div className="right-big-bar">
        <img src={MarketingRightBg} alt="" className="bar-bg" />
        <div className="mbar-content">
          <div
            className="dm-compaign"
            onClick={() => handleMarketing(1)}
            style={{
              backgroundColor: toggleMarketing[0] && "black",
            }}
          >
            <span>1</span>
            <div className="dmc-heading">Digital Marketing</div>
          </div>
          <div
            className="vm-compaign"
            onClick={() => handleMarketing(2)}
            style={{
              backgroundColor: toggleMarketing[1] && "black",
            }}
          >
            <span>2</span>
            <div className="vmc-heading">Video Marketing</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Marketing;
