import React from 'react';
import './what-makes-us-unique-comp.scss';
import { Tilt } from 'react-tilt'

// const Frame1 = "https://rentblob.blob.core.windows.net/turant/Frame1.png"
// const Frame2 = "https://rentblob.blob.core.windows.net/turant/Frame2.png"
import Frame1 from "../../img/PetFrame1.png"
import Frame2 from "../../img/PetFrame2.png"

const text = 'https://rentblob.blob.core.windows.net/pitch/img/Pitch-Deck/fourth-comp-img/our-usp-img.png';





export default function WhatMakesUsUniqueComp(props) {



    return (
        <div className="what-makes-us-unique-comp-main-container">
            <h1 className='bg-text'>USP</h1>
            <img className='text-img' src={text} alt="" />
            <h1 className="what-makes-us-unique-heading">
                what makes <br /> <b>us <span>unique</span></b>
            </h1>
            <Tilt
                options={{
                    reverse: true,
                    max: 20,
                }}
                className="raia-div"
            >
                <img className="pet-f1" src={Frame1} alt="" />
            </Tilt>
            <Tilt
                options={{
                    reverse: true,
                    max: 20,
                }}
                className="rscore-div"
            >
                <img src={Frame2} alt=""/>
            </Tilt>

             


        </div>)
}
