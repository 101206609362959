import React, { useState, useEffect } from "react";
import "./comp-one.scss";
import PetzingText from "../../../../img/comp6_1.png";
import PetzingImg from "../../../../img/comp6_2.png";
import ClutterText from "../../../../img/ClutterFreeText.png";

// const Phone1 = "https://rentblob.blob.core.windows.net/pitch/img/Pitch-Deck/sixth-comp-img/phone1.png";
// const Phone2 = "https://rentblob.blob.core.windows.net/pitch/img/Pitch-Deck/sixth-comp-img/phone2.png";
// const Frame1 = "https://rentblob.blob.core.windows.net/pitch/img/Pitch-Deck/sixth-comp-img/real-estate-agent-holding-house-key.png";

export default function CompOne(props) {
    const [isHover, setIsHover] = useState(false);

    useEffect(() => {
        props.HoverID === 1 && setTimeout((prev)=>{setIsHover(!prev)}, 200)
    }, [props.HoverID])

    return (
            <div className="comp-one-main-container">
                <div className="content-container1">
                 
                    <div className="petzing-div">
                        <img className="petzingtext-img" src={PetzingText} alt=""
                            style={{transform: isHover && `translateX(0vw)`}} 
                            />
                        <img className="clutter-text" src={ClutterText} alt=""
                        style={{transform: isHover && `translateX(0vw)`}}
                        />
                        <img className="petzzing-img" src={PetzingImg} alt=""
                        style={{transform: isHover && `translateY(-100%)`}}
                        />
                    </div>
                </div>
            </div>
    )
}