import React from "react";
import "./reviews.scss";

const Reviews = () => {
  const reviewsData = [
    {
      id: 1,
      name: "Shashank Sinha CEO, Drools Pet Care",
      text: "The industry boasts remarkable resilience - even during economic downturns, pet ownership remains steady, and pet care spending continues to rise. This stability makes pet care a lucrative and reliable space for investors seeking long-term gains",
    },
    {
      id: 2,
      name: "Salil Murthy Managing Director, ",
      name2: "Mars Petcare India",
      text: "We are optimistic about the continued growth of the pet care industry, driven by increasing disposable incomes, changing family structures, and a deeper appreciation for pets as companions",
    },
    {
      id: 3,
      name: "Rashi Sanon Narang Founder",
      name2: "Heads Up For Tails",
      text: "It's been inspiring to witness the influx of funding over the past few years. This financial support empowers companies to experiment, innovate, and expand the market",
    },
  ];

  return (
    <div className="reviews-main" >
      <div className="reviews-heading">What people say about us!</div>
      <div className="reviews-box">
        {reviewsData.map((item) => {
          return (
            <div
              className="review-item"
              key={item.id}
              style={{
                backgroundColor: item.id === 2 && "#60d6b4",
                color: item.id === 2 && "#212121",
              }}
            >
              {" "}
              <span
                className="quote-icon"
                style={{
                  opacity: item.id === 2 && "0",
                }}
              >
                &#8220;
              </span>
              <p>{item.text}</p>
              <h3>{item.name}</h3>
              <h3>{item.name2}</h3>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Reviews;
