import React, { useState, useEffect } from "react";
import "./comp-three.scss";
// import Frame1 from "../../../../img/BgComp2_3.png";
import PetShop from "../../../../img/petshopcontent.png";
import DogPhoto from "../../../../img/dogImage.png";
import PetSupplies from "../../../../img/petsupplies.png";
// const Frame1 = "https://rentblob.blob.core.windows.net/pitch/img/Pitch-Deck/sixth-comp-img/frame498.png";
// const Phone = "https://rentblob.blob.core.windows.net/pitch/img/Pitch-Deck/sixth-comp-img/phone5.png";


export default function CompThree(props) {

    const [isHover, setHover] = useState(false)

    function handleMouseEvent() {
        setHover(prev => { return !prev })
    }


    useEffect(() => {
        props.HoverID === 3 && setTimeout(handleMouseEvent, 200)
    }, [props.HoverID])

    return (
        <>
            <div className="comp-three-main-container"
                // onMouseEnter={handleMouseEvent}
                // onMouseLeave={handleMouseEvent}
            >
                <div className="content-container3">
                    {/* <img className="bg-img3" src={Frame1} alt=""/> */}
                    <img className="petshop" src={PetShop} alt="" style={{transform:isHover && `translateX(0)`}}/>
                    <img className="dogphoto" src={DogPhoto} alt="" style={{transform:isHover && `translate(0,-100%)`}}/>
                    <img className="petsupplies" src={PetSupplies} alt="" style={{transform:isHover && `translateY(-100%)`}}/>
                   
                  
                </div>
            </div>


        </>
    )
}