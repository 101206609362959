import React, { useState } from "react";
import "./mCompTwo.scss";
import Video1 from "../../../img/video1.mp4";
import Video2 from "../../../img/video2.mp4";
import Video3 from "../../../img/video3.mp4";
import Video4 from "../../../img/video4.mp4";

const MCompTwo = ({ toggleMarketing }) => {
  const [vmAnim, setVmAnim] = useState(1);
  const vmData = [
    {
      id: 1,
      mediaOne: Video1,
      mediaTwo: Video2,
    },
    {
      id: 2,
      mediaOne: Video3,
      mediaTwo: Video4,
    },
  ];
  return (
    <div
      className="mCompTwo"
      style={{
        transform: toggleMarketing[1] && "translateY(0%)",
      }}
    >
      <div className="mComTwo-wrapper">
        {vmData.map((item) => {
          return (
            <div
              className="video-card"
              key={item.id}
              style={{
                transform:
                  vmAnim === 1
                    ? "translate(0%)"
                    : vmAnim === 2
                    ? "translate(-104%)"
                    : "",
              }}
            >
              <video controls>
                <source src={item.mediaOne} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <video controls>
                <source src={item.mediaTwo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          );
        })}
      </div>
      <div className="mCompTwo-dots">
        {vmData.map((item) => {
          return (
            <p key={item.id}
              style={{
                backgroundColor: item.id === vmAnim && "#ff8603",
              }}
              onClick={() => setVmAnim(item.id)}
            ></p>
          );
        })}
      </div>
    </div>
  );
};

export default MCompTwo;
