import React from "react";
import "./thirdPD-card-comp.scss";

// const Icon1 = "https://rentblob.blob.core.windows.net/pitch/img/Pitch-Deck/third-comp-img/icon1.png";
// const Icon2 = "https://rentblob.blob.core.windows.net/pitch/img/Pitch-Deck/third-comp-img/icon2.png";
// const Icon3 = "https://rentblob.blob.core.windows.net/pitch/img/Pitch-Deck/third-comp-img/icon3.png";
import Icon1 from "../../img/PetIcon1.png";
import Icon2 from "../../img/PetIcon2.png";
import Icon3 from "../../img/PetIcon3.png";
import Icon4 from "../../img/PetIcon4.png";

export default function ThirdPDCardComp(props) {
  return (
    <>
      <div className="thirdPD-card-comp-main-container">
        <div className="top-part">
          <div className="icon-div">
            {props.id === 0 ? (
              <img src={Icon1} alt="" />
            ) : props.id === 1 ? (
              <img src={Icon2} alt="" />
            ) : props.id === 2 ? (
              <img src={Icon3} alt="" />
            ) : (
              props.id === 3 && <img src={Icon4} alt="" />
            )}
          </div>
        </div>
        <div className="bottom-part">
          {props.id === 0 ? (
            <h1 style={{ display: "block" }}>
              {" "}
              <span> Marketplace For </span>
              <br />
              <span style={{ color: "#FF8603", fontSize: "1.15vw" }}>
                {props.Heading}
              </span>
            </h1>
          ) : props.id === 1 ? (
            <h1 style={{ borderBottom: "2px solid #333232"}}>
              <span style={{ marginRight: "0.5vw" }}>{props.Heading}</span>
              <span style={{ color: "#FF8603" }}> CHECK </span>
            </h1>
          ) : props.id === 2 ? (
            <h1>
              <span style={{ marginRight: "0.5vw" }}>{props.Heading}</span>
              <span style={{ color: "#FF8603" }}> REWARDS </span>
            </h1>
          ) : (
            <h1 style={{ display: "block", borderBottom: "2px solid #333232" }}>
              {" "}
              <span> Platform For </span>
              <br />
              <span style={{ color: "#FF8603", fontSize: "1.25vw" }}>
                {props.Heading}
              </span>
            </h1>
          )}
          <p>
            {props.Para1}{" "}
            <span style={{ color: "#FF8603" }}>
              {props.id === 0
                ? "universal "
                : props.id === 1
                ? "standard "
                : props.id === 2
                ? "redeemed "
                : props.id === 3 && "blogs "}
            </span>
            <span>{props.Para2}</span>
          </p>

          {/* <div className="more-btn-div"
                        // onMouseLeave={props.HandleMouseFalseEven}
                        onClick={() => {
                            props.HandleMouseTrueEven()
                            props.HandleCardItemID(props.id)
                        }}
                       
                        style={{
                            boxShadow: (props.id + 1) === props.cardNumber ? "2px 4px 5px #333333" : "none"
                        }}
                    >
                        <p>MORE DETAILS</p>
                    </div> */}
        </div>
      </div>
    </>
  );
}
