import React from "react";
import "./slide-two.scss";
import HSlideTwo from "../../../img/s2-hero-img.png";
import S2HeroText from "../../../img/s2-hero-text.png";
import PlayStoreImg from "../../../img/s2-playstore.png";
import AppStoreImg from "../../../img/s2-appstore.png";
import S2Bar from "../../../img/s2-line.png";
import S2Hand from "../../../img/big-hand.png";

const SlideTwo = () => {
  return (
    <div className="home-slide-two-wrapper">
      <div className="s2-hero-text">
        <img src={S2HeroText} alt="" />
      </div>
      <div className="s2-text-wrapper">
        <p className="s2-text">Your Pawfect Friend !</p>
        <div className="s2-btn">
          <button>SHOP NOW</button>
        </div>
        <div className="app-download">
          <span>Download Our App</span>
          <div className="store-img">
            <img src={AppStoreImg} alt="" />
            <img src={PlayStoreImg} alt="" />
          </div>
        </div>
      </div>
      <div className="s2-hand">
        <img src={S2Hand} alt="" />
      </div>
      <div className="s2-hero-img">
        <img src={HSlideTwo} alt="" />
      </div>

      <img src={S2Bar} alt="" className="s2-bar" />
    </div>
  );
};

export default SlideTwo;
