import React from "react";
import "./range-card.scss";

export default function RangeCard({mainDetail,censusYear,censusCount,censusUnit,progressValue,ProgressBar}) {
    return (
        <>
            <div className="range-card-main-container">
                <div className="top-part-div">
                    <div className="left-part-div">
                        <p>{mainDetail}<br />
                            {/* <span>
                                {censusYear}
                            </span> */}
                        </p>
                    </div>
                    <div className="rigth-part-div">
                        <h1>{censusCount} <span>{censusUnit}</span></h1>
                    </div>
                </div>
                <div className="bottom-part-div">
                    <div className="main-div">
                        <div className="div1"
                        style={{
                            width: !ProgressBar && `${progressValue}%`
                        }}
                        ></div>
                        <div className="div2"
                        style={{
                            width: !ProgressBar && `${progressValue}%`                       
                        }}
                        ></div>
                        <div className="div3"
                        style={{
                            width: !ProgressBar && `${progressValue}%`                        
                        }}
                        ></div>
                    </div>

                </div>

            </div>
        </>
    )
}