import "./mhome.scss";
import MInstagram from "../../../img/m-insta.png";
import MFacebook from "../../../img/m-facebook.png";
import MAdwords from "../../../img/m-adwords.png";
import MDog1 from "../../../img/m-c1-first.png";
import MDog2 from "../../../img/m-c1-second.png";
import MDog3 from "../../../img/m-c1-third.png";

const MHome = ({ dogOne, dogTwo, dogThree }) => {
  return (
    <div className="mhome">
      <div className="mhome-wrapper">
        <div className="our-compaign">
          <p>OUR CAMPAIGN</p>
          <div className="msocial-icons">
            <img src={MInstagram} alt="" />
            <img src={MFacebook} alt="" />
            <img src={MAdwords} alt="" />
          </div>
        </div>
        <div className="mhero-text">
          <h2>“Pawsitively changing lives one pet at a time”</h2>
        </div>
        <div className="mhero-img">
          <div
            className="dog-img1"
            style={{
              transform: dogOne && "scale(1.2)",
              transformOrigin: "bottom",
              // filter: dogOne && "drop-shadow(1px -5px 12px #000000)",
            }}
          >
            <img src={MDog1} alt="" />
          </div>
          <div
            className="dog-img2"
            style={{
              transform: dogTwo && "scale(1.1)",
              transformOrigin: "bottom",
              // filter: dogTwo && "drop-shadow(1px -5px 12px #000000)",
            }}
          >
            <img src={MDog2} alt="" />
          </div>
          <div
            className="dog-img3"
            style={{
              transform: dogThree && "scale(1.2)",
              // filter: dogThree && "drop-shadow(1px -5px 12px #000000)",
              transformOrigin: "bottom",
            }}
          >
            <img src={MDog3} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MHome;
