import React, { useEffect, useState } from "react";
import "./tractionExtra.scss";
import Phone1 from "../../img/te-phone1.png";
import Phone2 from "../../img/te-phone2.png";
import Phone3 from "../../img/te-phone3.png";
import Phone4 from "../../img/te-phone4.png";

const TractionExtra = () => {
  const [phoneAnim, setPhoneAnim] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (phoneAnim >= 4) {
        setPhoneAnim(0);
      } else {
        setPhoneAnim(phoneAnim + 1);
      }
    }, 2000);
  }, [phoneAnim]);

  return (
    <div className="traction-extra">
      <div className="te-heading">
        <h2>TRACTION SO FAR</h2>
      </div>
      <div className="te-content">
        <div className="te-line">
          <span></span>{" "}
          <p>Pet services operational in Mumbai/Bangalore/Delhi/Pune.</p>
        </div>
        <div className="te-line">
          <span></span>{" "}
          <p>
            Pet products delivery Pan india and already delivered to 300+
            cities.
          </p>
        </div>
        <div className="te-line">
          <span></span> <p>Average 20K monthly website visitors.</p>
        </div>
        <div className="te-line">
          <span></span>{" "}
          <p>
            Over 2.5 L App Downloads on Apple Appstore and Google Play Store
          </p>
        </div>
        <div className="te-line">
          <span></span>{" "}
          <p>Month on Month revenue growth rate of more than 50%.</p>
        </div>
      </div>

      <div className="te-bottom">
        <div className="te-img-box1">
          <img
            src={Phone1}
            alt=""
            style={{
              transform: phoneAnim === 1 && "translateY(-10%)",
            }}
          />
        </div>
        <div className="te-img-box2">
          <img
            src={Phone2}
            alt=""
            style={{
              transform: phoneAnim === 2 && "translateY(10%)",
            }}
          />
        </div>
        <div className="te-img-box3">
          <img
            src={Phone3}
            alt=""
            style={{
              transform: phoneAnim === 3 && "translateY(-10%)",
            }}
          />
        </div>
        <div className="te-img-box4">
          <img
            src={Phone4}
            alt=""
            style={{
              transform: phoneAnim === 4 && "translateY(-10%)",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TractionExtra;
